'use client'

import React, { FC, useEffect, useState } from 'react'
import classNamesBind from 'classnames/bind'
import Ellipsis from './components/Ellipsis'
import ImageCarousel from './components/ImageCarousel'

import css from './styles.module.scss'
const classNames = classNamesBind.bind(css)

interface OwnProps {}

const OnboardingCarousel: FC<OwnProps> = (_: OwnProps) => {
  const [activeIndex, setActiveIndex] = useState(0)

  // Effects
  useEffect(() => {
    const quoteToggle = setInterval(() => {
      toggleActiveState()
    }, 8000)
    return () => clearInterval(quoteToggle)
  })

  // Functions
  const renderEllipsis = () => {
    return (
      <div className={classNames('ellipsis-container')}>
        <div
          onClick={() => {
            changeIndex(0)
          }}
        >
          <Ellipsis active={updateActiveState(0)} />
        </div>
        <div
          onClick={() => {
            changeIndex(1)
          }}
        >
          <Ellipsis active={updateActiveState(1)} />
        </div>
        <div
          onClick={() => {
            changeIndex(2)
          }}
        >
          <Ellipsis active={updateActiveState(2)} />
        </div>
      </div>
    )
  }

  const updateActiveState = (index: number) => {
    if (index === activeIndex) {
      return true
    }
  }

  const changeIndex = (index: number) => {
    setActiveIndex(index)
  }

  const toggleActiveState = () => {
    const increment = activeIndex + 1

    if (activeIndex >= 2) {
      setActiveIndex(0)
    } else {
      setActiveIndex(increment)
    }
  }

  // Render
  return (
    <div className={classNames('container')}>
      <ImageCarousel activeIndex={activeIndex} />
      <div className={classNames('select')}>{renderEllipsis()}</div>
    </div>
  )
}

export default OnboardingCarousel
