import React, { FC } from 'react'
import classNamesBind from 'classnames/bind'
import css from './styles.module.scss'

interface OwnProps {
  active?: boolean
}

const classNames = classNamesBind.bind(css)

const Ellipsis: FC<OwnProps> = ({ active }: OwnProps) => {
  const returnEllipsisActiveColor = () => {
    if (active) return '#2ca7ef'
    else return '#c4c4c4'
  }

  return (
    <div className={classNames('container')}>
      <svg
        width="11"
        height="10"
        viewBox="0 0 11 10"
        xmlns="http://www.w3.org/2000/svg"
        className={classNames('ellipsis')}
      >
        <circle cx="5.5" cy="5" r="5" fill={returnEllipsisActiveColor()} />
      </svg>
    </div>
  )
}

export default Ellipsis
